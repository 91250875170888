<template>
  <div style="margin-top: 8%;margin-left: 5%">
  <!--  调拨单主内容-->
    <div>
      <div>
        材料类型：{{ dbd_main.dbdType }}
      </div>
      <div>
        调拨编号：{{ dbd_main.dbdNum }}
      </div>
      <div>
        调拨时间：{{ dbd_main.dbdTime }}
      </div>
      <div>
        调出工区：{{ dbd_main.dcBdst }}
      </div>
      <div>
        调入工区：{{ dbd_main.drBdst }}
      </div>
    </div>
    <el-divider />
  <!--  调拨单主要数据-->
    <div v-for="(item, index) in dbd_detail_list" :key="index">
      <div style="display: grid;grid-template-columns: 1fr 1fr;">
        <div>
          材料名称：{{item.materialName}}
        </div>
        <div>
          规格型号：{{item.materialModel}}
        </div>
      </div>
      <div style="display: grid;grid-template-columns: 1fr 1fr;">
        <div>
          材料单位：{{item.materialUnit}}
        </div>
        <div>
          调拨数量：{{item.materialNum}}
        </div>
      </div>
      <div style="display: grid;grid-template-columns: 1fr 1fr;">
        <div>
          不含税单价(元)：{{item.materialNoTaxrate}}
        </div>
        <div>
          不含税金额(元)：{{item.materialMoney}}
        </div>
      </div>
      <div>
        备注：{{ item.remarks }}
      </div>
      <el-divider />
    </div>
    <div>
      合计: {{ all_money }}元
    </div>
    <el-divider />
    <div style="margin-left: 10%">
	    <el-button type="warning" @click="back_pending()">返回待处理页面</el-button>
      <el-button type="primary" @click="agree()">同意</el-button>
      <el-button type="danger" @click="back()">退回</el-button>
    </div>
  <!--  退回原因-->
    <div>
      <el-dialog
          v-model="dialogBack"
          title="退回原因"
          width="80%"
      >
        <el-input v-model="back_reason" placeholder="请输入退回原因"/>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogBack = false">取消</el-button>
            <el-button type="primary" @click="yes_back()"> 退回 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {setNowDate} from "@/utils/time";

export default {
  name: "Material_dbd_detail",
  inject: ['reload'],
  data() {
    return {
      dbd_main: [],
      dbd_detail_list: [],
      main_id: '',
      all_money: '',
      dialogBack: false,
      back_reason: '',
      user_id: '',
	    material_equiment: ''
    }
  },
  created() {
    this.main_id = this.$route.params.dbd_id
    this.user_id = this.$route.params.user_id
	  this.material_equiment = this.$route.params.material_equiment
    this.getDetail()
  },
  methods: {
    getDetail(){
      this.axios.get("/materialDbdMain/getDetailByMainId", (response) => {
        this.all_money = response.message
        this.dbd_main = response.obj2
        this.dbd_detail_list = response.obj3
      },{
        dbd_id: this.$route.params.dbd_id,
	      material_equiment: this.$route.params.material_equiment
      })
    },
    agree(){
      this.axios.post('/materialDbdMain/agreeDbd', (response) => {
        if (response.obj){
          ElMessage({
            message: '该调拨单已同意!',
            type: 'success'
          })
            this.$router.push({
                name: 'Material_pending',
                params: {
                    user_id: this.user_id
                }
            });
          this.axios.get('/materialDbdMain/getLcFlagById', (response1) => {
              for (let i = 0; i < response1.obj.length; i++){
                this.axios.post('/wxSendMessage/sendDbdSp', (response2) => {

                },{
                  user_id: response1.obj[i]
                })
              }
          },{
            id: this.main_id,
	          material_equiment: this.$route.params.material_equiment
          })
        }
      },{
        dbd_id: this.main_id,
        flag: 0,
        back_reason: this.back_reason,
        time: setNowDate(new Date())
      })
    },
	  back_pending(){
		  this.$router.push({
			  name: 'Material_pending',
			  params: {
				  user_id: this.user_id
			  }
		  });
	  },
    back(){
			console.log(this.dbd_main.lcFlag)
      if (this.dbd_main.lcFlag === 0){
        ElMessage.error('此调拨单不可再次退回!')
      }else {
        this.dialogBack = true
      }
    },
    yes_back(){
      this.axios.post('/materialDbdMain/agreeDbd', (response) => {
        if (response.obj){
          ElMessage({
            message: '该调拨单已退回!',
            type: 'success'
          })
            this.$router.push({
                name: 'Material_pending',
                params: {
                    user_id: this.user_id
                }
            });
          this.axios.get('/materialDbdMain/getLcFlagById', (response1) => {
            for (let i = 0; i < response1.obj.length; i++){
              this.axios.post('/wxSendMessage/sendDbdSp', (response2) => {

              },{
                user_id: response1[i]
              })
            }
          },{
            id: this.main_id,
	          material_equiment: this.$route.params.material_equiment
          })
        }
      },{
        dbd_id: this.main_id,
        flag: 1,
        back_reason: this.back_reason,
        time: setNowDate(new Date())
      })
    },
	  // 查询附件
	  getFileUrl(){
			this.axios.get('/equimentDbdUrl/getFileUrl', (response) => {

			},{
				
			})
	  }
  }
}
</script>

<style scoped>

</style>
